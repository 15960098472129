<template>
  <q-page padding>
    <div class="top-heading">Архив контента</div>

    <div class="q-my-lg">
      <q-input
        outlined
        dense
        v-model="search_query"
        label="Поиск..."
        @keyup.enter="apply_filters"
        debounce="700"
        clearable
      >
        <template v-slot:append>
          <q-icon
            name="search"
            @click="apply_filters"
            class="q-ml-md cursor-pointer"
          />
        </template>
      </q-input>
    </div>

    <q-tabs
      dense
      no-caps
      inline-label
      outside-arrows
      v-model="active_tab"
      active-color="primary"
      indicator-color="primary"
    >
      <q-tab name="all" label="Всё" icon="las la-folder-open" />
      <q-tab name="image" label="Изображения" icon="las la-image" />
      <q-tab name="post" label="Посты" icon="las la-pen-alt" />
      <q-tab name="content_plan" label="Контент-планы" icon="las la-stream" />
    </q-tabs>

    <div v-if="error" class="q-my-md text-negative">
      Ошибка при загрузке архива. Пожалуйста, попробуйте ещё раз.
    </div>

    <div v-else>
      <div
        v-if="archive_store.log_entries.length === 0 && !loading"
        class="text-center q-my-xl"
      >
        <div v-if="active_tab != 'all' || search_query">
          Ничего не найдено по условиям поиска.
          <div v-if="active_tab == 'image'">
            <q-btn
              class="q-my-lg"
              href="https://cogniscript.pro/blog/midjourney-generates-images/"
              target="_blank"
              icon="las la-external-link-alt"
              label="О генерации изображний в CogniScript"
            >
            </q-btn>
          </div>
          <div v-else-if="active_tab == 'content_plan'">
            <q-btn
              class="q-my-lg"
              href="https://cogniscript.pro/blog/how-much-is-content-plan/"
              target="_blank"
              icon="las la-external-link-alt"
              label="Сколько стоит контент-план?"
            >
            </q-btn>
          </div>
          <div v-else-if="active_tab == 'post'">
            <q-btn
              class="q-my-lg"
              href="https://cogniscript.pro/blog/stories/"
              target="_blank"
              icon="las la-external-link-alt"
              label="Пример экспертных сторис с помощью CogniScript"
            >
            </q-btn>
          </div>
        </div>
        <div v-else>
          На этой странице будет появляться весь контент, который вы создадите в
          CogniScript.
          <div>
            <q-btn class="q-my-lg" @click="router.push('/')"
              >Начать создавать</q-btn
            >
          </div>
        </div>
      </div>

      <q-list
        v-if="archive_store.log_entries.length"
        bordered
        separator
        class="q-my-lg rounded-borders"
      >
        <q-item
          v-for="entry in archive_store.log_entries"
          :key="entry.id"
          clickable
          @click="open_archive_item(entry)"
        >
          <q-item-section avatar>
            <q-icon :name="get_icon_for_entry(entry)" />
          </q-item-section>
          <q-item-section>
            <q-item-label
              ><span v-if="entry.is_deleted" class="label-deleted"
                >Удалено:
              </span>
              <span :class="entry.is_deleted ? 'text-strike' : ''">{{
                entry.content_topic
              }}</span>
            </q-item-label>
            <q-item-label caption>
              {{ format_date(entry.timestamp) }} &bull;
              {{ get_content_type_label(entry.content_type) }} &bull;
              {{ get_project_label(entry.user_profile) }}
              {{
                is_standalone_image(entry) ? '&bull; Вне контент-плана' : null
              }}
              {{
                is_standalone_post(entry) ? '&bull; Вне контент-плана' : null
              }}
            </q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-icon name="chevron_right" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <!-- Pagination Controls -->
    <div v-if="total_pages > 1" class="q-my-md flex justify-center">
      <q-pagination
        v-model="current_page"
        :max="total_pages"
        max-pages="5"
        :direction-links="total_pages > 5"
      />
    </div>
  </q-page>
</template>

<style scoped lang="scss">
/**
 * The import is required for the @extend (see below) to work.
 */
@import 'quasar/dist/quasar.sass';

.label-deleted {
  @extend .text-overline;
  line-height: inherit;
}
</style>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { useQuasar } from 'quasar';
import { useArchiveStore } from 'src/stores/archive';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { LogEntry, UserProfile } from 'src/components/models';
import { IAppPage } from 'src/router/navigationLinks';
import { useLoading } from 'src/includes/loading';
import { storeToRefs } from 'pinia';
import { useUserStore } from 'src/stores/user';
import {
  get_profile_label,
  profiles_limit_reached,
} from 'src/includes/user-profiles';
import {
  get_icon_for_entry,
  get_content_type_label,
  get_project_label,
  is_standalone_image,
  is_standalone_post,
  format_date,
} from './helpers';

const $q = useQuasar();
const router = useRouter();
const archive_store = useArchiveStore();
const user = useUserStore();

const search_query = ref('');
const active_tab = ref('all');
const current_page = ref(1);
const items_per_page = ref(10);

const { loading, error } = storeToRefs(archive_store);
useLoading(loading, error);

onMounted(() => {
  fetch_log_entries();
});

const fetch_log_entries = () => {
  archive_store.fetch_log_entries(
    current_page.value,
    items_per_page.value,
    active_tab.value,
    search_query.value
  );
};

watch(
  [current_page, items_per_page, active_tab, search_query],
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [new_current_page, new_items_per_page, new_active_tab, new_search_query],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [old_current_page, old_items_per_page, old_active_tab, old_search_query]
  ) => {
    if (
      new_items_per_page !== old_items_per_page ||
      new_active_tab !== old_active_tab ||
      new_search_query !== old_search_query
    ) {
      /**
       * If either of the search parameters has changed, we need to reset the
       * page number to 1 because we don't know how many search results there
       * will be.
       */
      current_page.value = 1;
    }
    fetch_log_entries();
  }
);

const total_pages = computed(() => {
  return Math.ceil(archive_store.total_entries / items_per_page.value);
});

const apply_filters = () => {
  current_page.value = 1;
  fetch_log_entries();
};

/** This function is triggered whenever a user clicks on an archive entry. Opens the corresponding piece of content (post, content plan, image, etc) */
const open_archive_item = (entry: LogEntry) => {
  let content_location: RouteLocationRaw;

  if (entry.content_type === 'post') {
    const standalone: boolean = is_standalone_post(entry);

    content_location = {
      name: standalone ? IAppPage.SingleStandaloneText : IAppPage.SinglePost,
      params: {
        post_id: entry.post_id,
        content_plan_id: standalone ? null : entry.content_plan_id
      }
    }
  } else if (entry.content_type === 'image') {
    const standalone: boolean = is_standalone_image(entry);

    content_location = {
      name: standalone ? IAppPage.CreateStandaloneImage : IAppPage.CreateImageForPost,
      params: {
        content_plan_id: entry.content_plan_id,
        post_id: entry.post_id,
      }
    }
  } else if (entry.content_type === 'content_plan') {
    if (entry.content_plan_id) {
      content_location = {
        name: IAppPage.SingleContentPlan,
        params: {
          content_plan_id: entry.content_plan_id,
        },
      };
    } else {
      content_location = {
        name: IAppPage.ContentPlans,
      };
    }
  } else {
    $q.notify({
      type: 'negative',
      position: 'top',
      message: 'Неизвестный тип контента.',
    });
    return;
  }

  if (entry.user_profile?.id == user.user.active_profile.id) {
    router.push(content_location);
  } else if (entry.user_profile) {
    $q.dialog({
      title: 'Контент в другом проекте',
      message:
        'Сейчас активен проект <strong>' +
        get_profile_label(user.user.active_profile) +
        '</strong>.<br><br>' +
        get_content_type_label(entry.content_type) +
        ' находится в другом проекте: <strong>' +
        entry.user_profile.profile_label +
        '</strong>. Переключиться на него?',
      html: true,
      style: 'min-width: 300px;',
      focus: 'none',
      ok: {
        label: 'Переключить проект',
        dense: true,
      },
      cancel: {
        label: 'Остаться здесь',
        outline: true,
        dense: true,
      },
    }).onOk(async () => {
      switch_profile_and_open_content(entry.user_profile, content_location);
    });
  }
};

function switch_profile_and_open_content(
  profile: UserProfile,
  content_path: RouteLocationRaw
) {
  if (profiles_limit_reached($q, false)) {
    return;
  }

  user.choose_user_profile(profile.id).then(() => {
    $q.notify({
      type: 'positive',
      position: 'top',
      html: true,
      message:
        'Переключили проект на <strong>' + profile.profile_label + '</strong>.',
    });
    router.push(content_path);
  });
}
</script>
