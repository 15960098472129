import { Notify } from 'quasar';
import { useMarketingKitStore } from 'src/stores/marketing-kit';
import { useUserStore } from 'src/stores/user';
import { RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router';
import { Router } from './index';
import { IAppPage } from './navigationLinks';
import { useContentPlanStore } from 'src/stores/content-plan';
import { useTopicsStore } from 'src/stores/post-topics';
import { Archive } from 'pages/Archive/index';

async function maybe_redirect_to_choose_module() {
  const user = useUserStore();
  const canAccess = await user.is_authenticated;

  /**
   * If the user has not chosen content plan, we are redirecting him to
   * the Choose Module page.
   */
  if (canAccess && !user.user.active_profile.content_plan_id && !user.error) {
    return '/choose-module';
  }

  return true;
}

async function check_if_mk_completed_and_adaptive_plan_still_active() {
  const mk = useMarketingKitStore();
  const user = useUserStore();

  if (!(await mk.get_mk_completed_async) && !mk.error) {
    Notify.create({
      type: 'warning',
      position: 'top',
      timeout: 0,
      message:
        'Для разработки контент-плана необходимо сначала заполнить маркетинг кит.',
      multiLine: true,
      icon: 'las la-edit',
      actions: [
        {
          outline: false,
          label: 'Заполнить маркетинг кит'.toUpperCase(),
          handler: () => {
            Router.push({ path: '/marketing-kit' });
          },
        },
        {
          color: 'white',
          outline: true,
          label: 'Закрыть'.toUpperCase(),
          handler: () => {
            Router.push({ path: '/' });
          },
        },
      ],
    });
    return false;
  }

  /**
   * This protects against the case when the user switches away from from the
   * adaptive module in another open instance of the app and then refreshes the
   * 'weekly plan' page in this instance. In that situation, without this check,
   * the attempt to load the weekly plan page would result in exception.
   */
  if (!user.has_adaptive_content_plan) {
    return '/';
  }

  return true;
}

type IAppRoute = RouteRecordRaw & {
  meta?: {
    breadcrumb:
      | string
      | ((route: RouteLocationNormalizedLoaded) => {
          label: string;
          link?: string;
        });
  };
};

/** Helper function that generates breadcrumbs object for a post route */
const getBreadcrumbForPost = (route: RouteLocationNormalizedLoaded) => {
  const postsStore = useTopicsStore();
  const post_id = parseInt(route.params.post_id as string);
  const post = postsStore.get_topic(post_id);
  const label = (post.topic || post.id) as string;
  return { label };
};

const routes: IAppRoute[] = [
  // Main / Home
  {
    path: '/',
    meta: {
      breadcrumb: 'Главная',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.Main,
        component: () => {
          const user = useUserStore();

          if (user.has_content_plan_for_project_based_home_page) {
            return import('pages/ProjectsBasedHomePage.vue');
          } else if (user.has_adaptive_content_plan) {
            return import('pages/HomePageVer2.vue');
          } else {
            return import('pages/IndexPage.vue');
          }
        },
      },
    ],
    beforeEnter: maybe_redirect_to_choose_module,
  },

  // Projects
  {
    path: '/projects',
    meta: {
      breadcrumb: 'Ваши проекты',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.Projects,
        component: () => import('pages/ManageProfiles.vue'),
        props: (route) => ({
          add_new: Number(route.query.add_new),
        }),
      },
    ],
  },

  // Archive
  {
    path: '/archive',
    meta: {
      breadcrumb: 'История',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.Archive,
        component: () => Archive,
      },
    ],
  },

  // Content-Plans
  {
    path: '/content-plans',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      breadcrumb: 'Контент-планы',
    },
    children: [
      {
        name: IAppPage.ContentPlans,
        path: '',
        component: () => import('pages/WeeklyPlan.vue'),
      },
    ],
    beforeEnter: check_if_mk_completed_and_adaptive_plan_still_active,
  },

  {
    path: '/content-plans/create',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      breadcrumb: 'Новый контент-план',
    },
    children: [
      {
        name: IAppPage.CreateContentPlan,
        path: '',
        component: () => import('pages/CreateContentPlan.vue'),
      },
    ],
    beforeEnter: check_if_mk_completed_and_adaptive_plan_still_active,
  },

  // Choose Module
  {
    path: '/choose-module',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: IAppPage.ChooseModule,
        path: '',
        component: () => import('pages/ChooseModule.vue'),
      },
    ],
  },

  // {
  //   path: '/content-plans/:content_plan_id',
  //   component: () => import('layouts/MainLayout.vue'),
  //   meta: {
  //     breadcrumb: (route: IDynamicBreadcrumbRouteInput) => ({
  //       label: `User ${route.params.id}`,
  //       link: `/users/${route.params.id}`,
  //     }),
  //   },
  //   children: [
  //     {
  //       path: '',
  //       name: IAppLocationName.SingleContentPlan,
  //       component: () => import('pages/ContentPlan.vue'),
  //       props: (route) => ({
  //         content_plan_id: Number(route.params.content_plan_id),
  //       }),
  //     },
  //   ],
  // },

  // Single Content Plan
  {
    path: '/content-plans/:content_plan_id',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      breadcrumb: (route: RouteLocationNormalizedLoaded) => {
        const content_plan_id = route.params.content_plan_id as string;
        const store = useContentPlanStore();

        const [plan] = store.content_plans.filter(
          (item) => item.id.toString() === content_plan_id
        );

        const label = plan ? plan.name : `Контент-план ${content_plan_id}`;

        return { label };
      },
    },
    children: [
      {
        path: '',
        component: () => import('pages/ContentPlanVer3.vue'),
        name: IAppPage.SingleContentPlan,
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
        }),
      },
    ],
  },

  // Standalone texts
  {
    path: '/standalone-texts',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      breadcrumb: 'Тексты вне контент-плана',
    },
    children: [
      {
        path: '',
        name: IAppPage.StandaloneTexts,
        component: () => import('pages/UserPersonalTexts.vue'),
      },
    ],
  },

  // Single standalone text
  {
    path: '/standalone-texts/:content_plan_id?/text/:post_id',
    meta: {
      breadcrumb: (route: RouteLocationNormalizedLoaded) => {
        const postsStore = useTopicsStore();
        const post_id = parseInt(route.params.post_id as string);
        const post = postsStore.get_topic(post_id);
        const label = (post.topic || post.id) as string;
        return { label };
      },
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.SingleStandaloneText,
        component: () => import('pages/PostPage.vue'),
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  // Standalone Images Section
  {
    path: '/standalone-images',
    meta: {
      breadcrumb: 'Изображения',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.StandaloneImages,
        component: () => import('pages/StandaloneImages.vue'),
      },
    ],
  },

  // Single Standalone Image
  {
    path: '/standalone-images/:content_plan_id?/image/:post_id/',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      breadcrumb: getBreadcrumbForPost,
    },

    children: [
      {
        path: '',
        name: IAppPage.CreateStandaloneImage,
        component: () => import('pages/CreateStandaloneImage.vue'),
        props: (route) => ({
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  //Create or edit images for a post
  {
    path: '/content-plans/:content_plan_id?/post/:post_id/images',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      breadcrumb: 'Создать изображение',
    },
    children: [
      {
        path: '',
        name: IAppPage.CreateImageForPost,
        component: () => import('pages/CreateImageForPost.vue'),
        props: (route) => ({
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  // Single Post
  {
    path: '/content-plans/:content_plan_id?/post/:post_id',
    meta: {
      breadcrumb: (route: RouteLocationNormalizedLoaded) => {
        const postsStore = useTopicsStore();
        const post_id = parseInt(route.params.post_id as string);
        const post = postsStore.get_topic(post_id);
        const label = (post.topic || `Пост ${post.id}`) as string;
        return { label };
      },
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.SinglePost,
        component: () => import('pages/PostPage.vue'),
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  {
    path: '/marketing-kit',
    meta: {
      breadcrumb: 'Маркетинг-кит',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.MarketingKit,
        component: () => import('pages/MarketingKit.vue'),
      },
    ],
    beforeEnter: maybe_redirect_to_choose_module,
  },

  {
    path: '/chat/:chat_id?',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.Chat,
        component: () => import('pages/ChatPage.vue'),
        props: (route) => ({
          chat_id: Number(route.params.chat_id),
        }),
      },
    ],
  },

  {
    path: '/oauth-redirect',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/AuthCallback.vue') }],
  },

  {
    path: '/login',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'now/:srv',
        component: () => import('pages/Login.vue'),
        props: true,
      },
      {
        path: '',
        component: () => import('pages/Login.vue'),
        props: false,
      },
    ],
  },

  {
    path: '/logout',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Logout.vue') }],
  },

  {
    path: '/profile',
    meta: {
      breadcrumb: 'Профиль',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.Profile,
        component: () => import('pages/ProfilePage.vue'),
      },
    ],
  },

  {
    path: '/admin-posts/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.AdminPosts,
        component: () => import('pages/AdminPostDetails.vue'),
        props: true,
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/ErrorNotFound.vue') },
    ],
  },
];

export default routes;
