import { QVueGlobals } from 'quasar';
import { UserProfile } from 'src/components/models';
import { useMarketingKitStore } from 'src/stores/marketing-kit';
import { useUserStore } from 'src/stores/user';

export function notify_of_profile_change_and_reload(
  $q: QVueGlobals,
  chosen_profile: UserProfile,
  redirect_to_home = true
) {
  $q.dialog({
    title: 'Готово',
    message:
      'Переключили проект на <strong>"' +
      get_profile_label(chosen_profile) +
      '"</strong>.',
    html: true,
    style: 'min-width: 300px;',
    persistent: true,
    focus: 'none',
    ok: {
      label: 'Перейти к проекту',
      dense: true,
    },
  }).onOk(() => {
    if (redirect_to_home) {
      window.location.href = '/';
      return;
    }
    const user = useUserStore();
    const marketing_kit = useMarketingKitStore();

    marketing_kit.get_mk_completed_async.then(() => {
      if (marketing_kit.get_mk_completed) {
        window.location.href = user.link_to_content_plan;
      } else {
        window.location.href = '/marketing-kit';
      }
    });
  });
}

export function get_profile_label(profile: UserProfile) {
  return profile?.profile_label ? profile.profile_label : 'Основной';
}

export function profiles_limit_reached(
  $q: QVueGlobals,
  fail_if_reached_but_not_exceeded: boolean
) {
  const user = useUserStore();
  const max_num_profiles =
    user.get_user.subscription_plan?.max_num_profiles || 50;

  if (
    user.get_user_profiles.length > max_num_profiles ||
    (user.get_user_profiles.length == max_num_profiles &&
      fail_if_reached_but_not_exceeded)
  ) {
    $q.notify({
      type: 'negative',
      position: 'center',
      timeout: 0,
      message:
        (user.get_user_profiles.length == max_num_profiles
          ? 'Достигнут'
          : 'Превышен') +
        ' лимит проектов в рамках тарифного плана: ' +
        max_num_profiles +
        '.',
      html: true,
      classes: 'max-width',
      multiLine: true,
      actions: [{ outline: true, label: 'ПОНЯТНО', color: 'yellow' }],
    });
    return true;
  }
  return false;
}
