import { LogEntry, UserProfile } from 'src/components/models';

export const get_content_type_label = (
  content_type: LogEntry['content_type']
) => {
  switch (content_type) {
    case 'post':
      return 'Пост';
    case 'image':
      return 'Изображение';
    case 'content_plan':
      return 'Контент-план';
    default:
      return 'Контент';
  }
};

export const get_icon_for_entry = (entry: LogEntry) => {
  switch (entry.content_type) {
    case 'post':
      return 'las la-pen-alt';
    case 'image':
      return 'las la-image';
    case 'content_plan':
      return 'las la-stream';
    default:
      return 'las la-file';
  }
};

/** Date formatting for archive UI */
export const format_date = (timestamp: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return timestamp.toLocaleDateString('ru-RU', options);
};

/** This function takes a `LogEntry` and returns `true` if it is a standalone image. All other content types will return false */
export const is_standalone_image = (entry: LogEntry): boolean => {
  if (entry.content_type !== 'image') return false;
  const content_plan_id = entry.content_plan_id;
  return content_plan_id === entry.user_profile.image_requests_id;
};

export const is_standalone_post = (entry: LogEntry): boolean => {
  if (entry.content_type !== 'post') return false;
  const content_plan_id = entry.content_plan_id;
  return content_plan_id === entry.user_profile.personal_texts_id;
};

export const get_project_label = (user_profile: UserProfile) => {
  return user_profile?.profile_label ?? 'Основной проект';
};
