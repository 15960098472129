import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { LogEntry } from 'src/components/models';

export const useArchiveStore = defineStore('archive', {
  state: () => ({
    log_entries: [] as LogEntry[],
    total_entries: 0,
    loading: false,
    error: false,
  }),

  actions: {
    async fetch_log_entries(
      page = 1,
      page_size = 10,
      content_type = 'all',
      search = ''
    ) {
      this.loading = true;
      this.error = false;
      try {
        const response = await api.get('/archive/', {
          params: {
            page,
            page_size,
            content_type,
            search_query: search,
          },
        });
        this.log_entries = response.data.log_entries.map((entry: LogEntry) => ({
          ...entry,
          timestamp: new Date(entry.timestamp),
        }));
        this.total_entries = response.data.total_entries;
      } catch (err) {
        this.error = true;
        console.error('Error fetching log entries:', err);
      } finally {
        this.loading = false;
      }
    },
  },

  getters: {
    get_log_entries_by_type: (state) => (type: string) => {
      return state.log_entries.filter((entry) => entry.content_type === type);
    },
  },
});
