import { useQuasar } from 'quasar';
import { watch, Ref } from 'vue';

export const useLoading = (
  loading_ref: Ref<boolean>,
  error_ref: Ref<boolean>
) => {
  const $q = useQuasar();

  watch(
    loading_ref,
    (is_loading) => {
      if (is_loading) {
        $q.loading.show();
      } else {
        $q.loading.hide();
      }
    },
    /**
     * The 'immediate' flag is required for the spinner to show up on the Post
     * page. For some reason, it isn't working without it -- this watcher never
     * gets invoked.
     */
    { immediate: true }
  );
};
